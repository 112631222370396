<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">For the redox reaction shown here, answer each of the questions below:</p>

      <p class="mb-4 pl-6">
        <chemical-latex content="Cu(s) + 2 AgNO3(aq) -> 2 Ag(s) + Cu(NO3)2(aq)" />
      </p>

      <p class="mb-n3">Which element gets oxidized?</p>

      <v-radio-group v-model="inputs.oxidizedElement">
        <div v-for="option in redoxedOptions" :key="option.value">
          <v-radio
            class="d-inline-block pl-6 my-0 py-0"
            :value="option.value"
            :disabled="!allowEditing"
          />
          <stemble-latex :content="option.text" />
        </div>
      </v-radio-group>

      <p class="mb-n3">Which element gets reduced?</p>

      <v-radio-group v-model="inputs.reducedElement">
        <div v-for="option in redoxedOptions" :key="option.value">
          <v-radio
            class="d-inline-block pl-6 py-0 my-0"
            :value="option.value"
            :disabled="!allowEditing"
          />
          <stemble-latex :content="option.text" />
        </div>
      </v-radio-group>

      <p class="mb-n3">Which species is the oxidizing agent?</p>

      <v-radio-group v-model="inputs.oxidizingAgent">
        <div v-for="option in redoxAgentOptions" :key="option.value">
          <v-radio
            class="d-inline-block pl-6 py-0 my-0"
            :value="option.value"
            :disabled="!allowEditing"
          />
          <stemble-latex :content="option.text" />
        </div>
      </v-radio-group>

      <p class="mb-n3">Which species is the reducing agent?</p>

      <v-radio-group v-model="inputs.reducingAgent">
        <div v-for="option in redoxAgentOptions" :key="option.value">
          <v-radio
            class="d-inline-block pl-6 py-0 my-0"
            :value="option.value"
            :disabled="!allowEditing"
          />
          <stemble-latex :content="option.text" />
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question74',
  components: {StembleLatex, ChemicalLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        oxidizedElement: null,
        reducedElement: null,
        oxidizingAgent: null,
        reducingAgent: null,
      },
      redoxedOptions: [
        {text: '$\\ce{Cu}$', value: 'Cu'},
        {text: '$\\ce{Ag}$', value: 'Ag'},
        {text: '$\\ce{N}$', value: 'N'},
        {text: '$\\ce{O}$', value: 'O'},
      ],
      redoxAgentOptions: [
        {text: '$\\ce{Cu}$', value: 'Cus'},
        {text: '$\\ce{Ag+}$', value: 'Ag+'},
        {text: '$\\ce{NO3-}$', value: 'NO3-'},
        {text: '$\\ce{Ag}$', value: 'Ags'},
        {text: '$\\ce{Cu^2+}$', value: 'Cu2+'},
      ],
    };
  },
};
</script>
